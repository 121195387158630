import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'; // Import Link and Router components
import { useState } from 'react';  // for the navbar
import './App.css';  // Import the existing CSS file

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomeSection />} />
          <Route path="/survey-findings" element={<ContentSection />} />
          <Route path="/taxonomy" element={<TaxonomySection />} />
          <Route path="/team" element={<TeamSection />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="Navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={() => setIsOpen(false)}>
          🏠Home
        </Link>
        <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={isOpen ? 'nav-menu active' : 'nav-menu'}>
          <li><Link to="/survey-findings" onClick={toggleMenu}>📈Survey Findings</Link></li>
          <li><Link to="/taxonomy" onClick={toggleMenu}>📚Taxonomy</Link></li>
          <li><Link to="/team" onClick={toggleMenu}>👥Our Team</Link></li>
        </ul>
      </div>
    </nav>
  );
}

function HomeSection() {
  return (
    <section id="home" className="home-section">
      <div className="home-content">
        <h1>Energy-Efficient Web Protocols and Architectures for Sustainable Computing</h1>
        <p>
          Our survey focuses on energy-efficient web protocols and architectures for sustainable computing. It aims to address challenges like high energy consumption in cloud data centers, IoT, and smart grids.
        </p>
        <p>
          Recent trends show a shift towards greener technologies, such as energy-efficient routing in IoT and sustainable cloud computing practices.
        </p>
        <p>
          Future research directions include enhancing energy optimization in cloud and edge computing, improving energy-efficient protocols, and exploring renewable energy integration.
        </p>
      </div>
    </section>
  );
}

function ContentSection() {
  return (
    <section id="content" className="content-section">
      <h2>Survey Findings</h2>
      <p className="content-description">
        The survey includes a wide range of studies focusing on energy efficiency in different domains:
      </p>
      <ul className="content-list">
        <li>
          <strong>Energy-efficient techniques in sustainable cloud computing:</strong> Explores energy-saving strategies for cloud data centers, emphasizing optimization in hardware and software.
        </li>
        <li>
          <strong>Green cloud computing:</strong> Focuses on balancing energy efficiency with business agility.
        </li>
        <li>
          <strong>Energy efficiency in cloud computing data centers:</strong> Analyzes software solutions for reducing power consumption in data centers.
        </li>
        <li>
          <strong>Energy-efficient fault tolerance techniques in green cloud computing:</strong> Investigates methods to maintain system reliability while minimizing energy consumption.
        </li>
        <li>
          <strong>Green internet of things:</strong> Addresses advancements in IoT protocols to achieve energy efficiency.
        </li>
        <li>
          <strong>Energy-efficient routing techniques in WSNs focusing IoT applications and enhancing fog computing paradigm:</strong> Explores routing strategies to optimize energy consumption in IoT and wireless sensor networks.
        </li>
        <li>
          <strong>ESEERP: Enhanced Smart Energy Efficient Routing Protocol for IoT in Wireless Sensor Nodes:</strong> Proposes a novel routing protocol aimed at reducing energy usage in IoT sensor networks.
        </li>
        <li>
          <strong>Deep-green:</strong> Examines a decentralized approach to achieving energy efficiency in industrial IoT applications.
        </li>
        <li>
          <strong>Energy efficiency in smart homes and smart grids:</strong> Delves into methods for optimizing energy use in residential and grid-based systems.
        </li>
        <li>
          <strong>Intelligent Energy-Efficient Buildings:</strong> Provides a comprehensive review of energy-saving technologies and methodologies for building management systems.
        </li>
        <li>
          <strong>Task Offloading and Energy Harvesting:</strong> Discusses a differential evolution-based method to optimize task offloading in edge computing systems with energy harvesting.
        </li>
        <li>
          <strong>Machine-to-Machine Communications:</strong> Details a learning-based approach to energy-efficient channel selection in cognitive M2M communications.
        </li>
        <li>
          <strong>Wireless Sensor Networks:</strong> Analyzes clustering methods to enhance energy efficiency, proposing a new energy-oriented taxonomy.
        </li>
        <li>
          <strong>Cloud Computing Fault Tolerance:</strong> Examines proactive fault tolerance methods that preemptively address VM deterioration to optimize energy consumption.
        </li>
        <li>
          <strong>Sustainable Cloud Development:</strong> Explores the role of cloud computing in promoting sustainable development.
        </li>
        <li>
          <strong>Energy-Efficient Networks:</strong> Reviews sustainable techniques in green communication systems, urging further research.
        </li>
        <li>
          <strong>Green Computing Techniques:</strong> Explores balancing energy efficiency and performance in ICT systems.
        </li>
        <li>
          <strong>Green Computing Survey:</strong> Highlights the need for more energy-efficient IT hardware and software.
        </li>
        <li>
          <strong>Sustainable Production Protocols:</strong> Examines energy-saving protocols in environmental and industrial monitoring.
        </li>
        <li>
          <strong>Cloud & Web for Green Transformation:</strong> Discusses the role of cloud, AI, and IoT in building scalable, energy-efficient systems.
        </li>
      </ul>
    </section>
  );
}

function TaxonomySection() {
  return (
    <section id="taxonomy" className="TaxonomySection">
      <h2>Taxonomy</h2>
      <table>
        <thead>
          <tr>
            <th>Paper</th>
            <th>Domain</th>
            <th>Methodology</th>
            <th>Findings</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-label="Paper">
              <span>A systematic survey on energy-efficient techniques in sustainable cloud computing</span>
            </td>
            <td data-label="Domain">
              <span>Cloud Computing</span>
            </td>
            <td data-label="Methodology">
              <span>Literature Review</span>
            </td>
            <td data-label="Findings">
              <span>Identifies and synthesizes strategies to reduce power consumption in cloud data centers, emphasizing hardware and software optimizations.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Green cloud computing: A sustainable energy-efficiency approach for business rapidity and the environment</span>
            </td>
            <td data-label="Domain">
              <span>Cloud Computing</span>
            </td>
            <td data-label="Methodology">
              <span>Conceptual Analysis</span>
            </td>
            <td data-label="Findings">
              <span>Discusses approaches to balance energy efficiency and business agility in cloud services.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Energy efficiency in cloud computing data centers: a survey on software technologies</span>
            </td>
            <td data-label="Domain">
              <span>Cloud Computing</span>
            </td>
            <td data-label="Methodology">
              <span>Comparative Study</span>
            </td>
            <td data-label="Findings">
              <span>Evaluates various software-based approaches for reducing energy use in cloud data centers.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Energy efficient fault tolerance techniques in green cloud computing</span>
            </td>
            <td data-label="Domain">
              <span>Cloud Computing</span>
            </td>
            <td data-label="Methodology">
              <span>Analytical Survey</span>
            </td>
            <td data-label="Findings">
              <span>Analyzes fault tolerance methods that reduce energy consumption while ensuring reliability.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Green internet of things: The next generation energy efficient internet of things</span>
            </td>
            <td data-label="Domain">
              <span>IoT</span>
            </td>
            <td data-label="Methodology">
              <span>State-of-the-Art Review</span>
            </td>
            <td data-label="Findings">
              <span>Surveys the latest advancements in IoT protocols for achieving energy efficiency.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>A survey on energy efficient routing techniques in WSNs focusing IoT applications and enhancing fog computing paradigm</span>
            </td>
            <td data-label="Domain">
              <span>IoT</span>
            </td>
            <td data-label="Methodology">
              <span>Thematic Analysis</span>
            </td>
            <td data-label="Findings">
              <span>Investigates energy-saving routing strategies in wireless sensor networks (WSNs) for IoT.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>ESEERP: Enhanced Smart Energy Efficient Routing Protocol for Internet of Things in Wireless Sensor Nodes</span>
            </td>
            <td data-label="Domain">
              <span>IoT</span>
            </td>
            <td data-label="Methodology">
              <span>Protocol Development</span>
            </td>
            <td data-label="Findings">
              <span>Designs a novel routing protocol aimed at reducing energy consumption in IoT sensor nodes.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Deep-green: A dispersed energy-efficiency computing paradigm for green industrial IoT</span>
            </td>
            <td data-label="Domain">
              <span>Industrial IoT</span>
            </td>
            <td data-label="Methodology">
              <span>Framework Proposal</span>
            </td>
            <td data-label="Findings">
              <span>Proposes a decentralized approach for improving energy efficiency in industrial IoT environments.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>A survey on energy efficiency in smart homes and smart grids</span>
            </td>
            <td data-label="Domain">
              <span>Smart Homes & Grids</span>
            </td>
            <td data-label="Methodology">
              <span>Synthesis Review</span>
            </td>
            <td data-label="Findings">
              <span>Integrates findings on optimizing energy consumption in smart home and grid networks.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>A Meta-Survey on Intelligent Energy-Efficient Buildings</span>
            </td>
            <td data-label="Domain">
              <span>Smart Buildings</span>
            </td>
            <td data-label="Methodology">
              <span>Meta-Analysis</span>
            </td>
            <td data-label="Findings">
              <span>Aggregates and evaluates various techniques for energy savings in building management systems.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Energy-Efficient Task Offloading Based on Differential Evolution in Edge Computing System With Energy Harvesting</span>
            </td>
            <td data-label="Domain">
              <span>Edge Computing</span>
            </td>
            <td data-label="Methodology">
              <span>Differential Evolution Optimization</span>
            </td>
            <td data-label="Findings">
              <span>Optimizes energy efficiency in edge computing with significant gains in micro-equipment service life.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Learning-Based Energy-Efficient Channel Selection for Edge Computing-Empowered Cognitive Machine-to-Machine Communications</span>
            </td>
            <td data-label="Domain">
              <span>Machine-to-Machine Communications</span>
            </td>
            <td data-label="Methodology">
              <span>Adversarial Multi-Armed Bandit</span>
            </td>
            <td data-label="Findings">
              <span>Develops an optimal long-term channel selection strategy that improves energy efficiency.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Towards Energy Efficient Clustering in Wireless Sensor Networks: A Comprehensive Review</span>
            </td>
            <td data-label="Domain">
              <span>Sensor Networks</span>
            </td>
            <td data-label="Methodology">
              <span>Feature-based Classification</span>
            </td>
            <td data-label="Findings">
              <span>Proposes a new classification system that facilitates energy-oriented analysis of clustering protocols.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>An Energy Efficient Agent Aware Proactive Fault Tolerance for Preventing Deterioration of Virtual Machines Within Cloud Environment</span>
            </td>
            <td data-label="Domain">
              <span>Cloud Computing</span>
            </td>
            <td data-label="Methodology">
              <span>Proactive Fault Tolerance</span>
            </td>
            <td data-label="Findings">
              <span>Introduces energy-efficient proactive fault tolerance mechanisms to prevent VM deterioration.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Cloud computing for sustainable development: An analysis of environmental, economic and social benefits</span>
            </td>
            <td data-label="Domain">
              <span>Cloud Computing</span>
            </td>
            <td data-label="Methodology">
              <span>Sustainability Analysis</span>
            </td>
            <td data-label="Findings">
              <span>Highlights the potential of cloud computing to enable sustainable practices across multiple domains.</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>The Need for Energy-Efficient Networks</span>
            </td>
            <td data-label="Domain">
              <span>Green communication systems</span>
            </td>
            <td data-label="Methodology">
              <span>Energy-efficient transmission, network optimization</span>
            </td>
            <td data-label="Findings">
              <span>Ultra-spectra communication systems reduce energy consumption</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Energy-Efficient Computing and Green Computing Techniques</span>
            </td>
            <td data-label="Domain">
              <span>Computing and ICT</span>
            </td>
            <td data-label="Methodology">
              <span>Intuitionist fuzzy decision-making, energy-efficient hardware/software</span>
            </td>
            <td data-label="Findings">
              <span>Efficient algorithms rank highest in performance for green computing</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Green Computing for Energy Transition</span>
            </td>
            <td data-label="Domain">
              <span>IT and green computing</span>
            </td>
            <td data-label="Methodology">
              <span>Green algorithms, energy-efficient hardware, renewable energy</span>
            </td>
            <td data-label="Findings">
              <span>Energy consumption in IT projected to rise, green computing reduces environmental impact</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Sustainable Production with Energy-Efficient Protocols</span>
            </td>
            <td data-label="Domain">
              <span>Industrial production, natural resource management</span>
            </td>
            <td data-label="Methodology">
              <span>BLE, LoRaWAN, wireless monitoring, sensors</span>
            </td>
            <td data-label="Findings">
              <span>Effective in optimizing energy use in production and resource management</span>
            </td>
          </tr>
          <tr>
            <td data-label="Paper">
              <span>Cloud Computing, AI, IoT, and Green Transformation</span>
            </td>
            <td data-label="Domain">
              <span>Cloud and enterprise systems</span>
            </td>
            <td data-label="Methodology">
              <span>Scalable cloud infrastructure, AI, IoT, predictive maintenance</span>
            </td>
            <td data-label="Findings">
              <span>AI and IoT drive sustainable work practices, support green transformation</span>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}

function TeamSection() {
  return (
    <section id="team" className="TeamSection">
      <h2>Our Team</h2>
      <div className="TeamMembers">
        <div className="TeamMember">
          <img src="alessandro.jpg" alt="Alessandro Sangiorgi" />
          <h3>Alessandro Sangiorgi</h3>
          <p>Focused on energy efficiency in edge computing, wireless sensor networks (WSNs), and cloud computing. Heavily contributed on the website skeleton and managed all DevOps tasks. Also contributed to the Taxonomy section of this survey.</p>
        </div>
        <div className="TeamMember">
          <img src="image.png" alt="Charitha Vennapusala" />
          <h3>Charitha Vennapusala</h3>
          <p>Researched energy-efficient cloud computing techniques and summarized findings. Contributed to the project's website and Survey Findings section.</p>
        </div>
        <div className="TeamMember">
          <img src="image2.png" alt="Akshitha Poreddy" />
          <h3>Akshitha Poreddy</h3>
          <p>Reviewed methodologies for green IoT systems and sustainable cloud strategies. Assisted in curating the Taxonomy section.</p>
        </div>
        <div className="TeamMember">
          <img src="Rohanth.jpg" alt="Rohanth Gundu" />
          <h3>Rohanth Gundu</h3>
          <p>Investigated sustainable web protocols and contributed to the development of the project's website structure.</p>
        </div>
        <div className="TeamMember">
          <img src="path_to_photo" alt="Henry Barsanti" />
          <h3>Henry Barsanti</h3>
          <p>Examined green avenues for data bases and web protocals and constributed to the survey paper.</p>
        </div>
      </div>
    </section>
  );
}

function Footer() {
  return (
    <footer className="Footer">
      <p>&copy; 2024 Group 11 website</p>
    </footer>
  );
}

export default App;

